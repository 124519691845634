import { render, staticRenderFns } from "./MostTrustedBpo.vue?vue&type=template&id=4c72bd40&scoped=true"
import script from "./MostTrustedBpo.vue?vue&type=script&lang=js"
export * from "./MostTrustedBpo.vue?vue&type=script&lang=js"
import style0 from "./MostTrustedBpo.vue?vue&type=style&index=0&id=4c72bd40&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c72bd40",
  null
  
)

export default component.exports